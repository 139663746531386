<template>

  <v-row class="inner-content">
    <v-col>
      <NegativeKeywordsTable />
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: 'NKWList',

  components: {
    NegativeKeywordsTable: () => import('@/components/tables/NegativeKeywordsTable.vue')
  },
}
</script>
